<template>
  <div class="card card-custom">
    <div class="card-body p-0">
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-21">
          <div class="col-md-12">
            <OpeningTimes></OpeningTimes>
          </div>
        </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import OpeningTimes from "@/view/pages/business/components/OpeningTimes";

export default {
  name: "OpeningTime",
  components: {
    OpeningTimes,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Business Settings" },
      { title: "Opening Times" }
    ]);

  },
  methods: {
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });
    },

    cancel : function()
    {

    }
  }
};
</script>
